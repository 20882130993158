.App {
  text-align: center;
  font-family: "Arial", sans-serif;
}

.board {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  width: 300px;
  height: 300px;
  margin: 0 auto;
}

.board--red-blink {
  animation: blink 1s linear infinite;
}

@keyframes blink {
  0%,
  100% {
    background-color: transparent;
  }
  50% {
    background-color: red;
  }
}

.square {
  background-color: white;
  border: 1px solid #999;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  height: 100px;
  padding: 0;
  text-align: center;
  width: 100px;
}

.game-info {
  margin-top: 20px;
}

button {
  cursor: pointer;
}

button:focus {
  outline: none;
}